<!--
 * @Author: Dyf
 * @LastEditors: Dyf
 * @Date: 2023-03-22 18:14:02
 * @LastEditTime: 2023-07-05 18:08
 * @Descripttion: 学生转班管理
-->
<style lang="scss" scoped>
.student-transfer {
    @include innerPage;
    @include pageHead(-20px);
    @include pageTab;
    @include defalutTable(calc(100% - 248px), 38px);
    @include pageFoot;

    .page-head {
        .search-form {
            width: 448px;
            margin-right: 70px;
        }
    }

    ::v-deep .el-table {

        thead .el-checkbox {
            padding-right: 0;

            &__inner {
                display: inline-block;
            }
        }
    }

    .dialog-container {
        padding-top: 30px;
    }
}
</style>
<template>
    <section class="student-transfer">
        <div class="page-head">
            <img class="bitmap" src="@assets/images/bitmap-head.png" alt="">
            <div class="head-wrapper">
                <breadcrumb />
                <div class="search-form">
                    <el-form inline>
                        <el-form-item>
                            <el-input v-model.trim="searchKey" maxlength="100" placeholder="输入学生姓名" clearable>
                                <span slot="prefix" class="iconfont">&#xe61c;</span>
                            </el-input>
                        </el-form-item>
                    </el-form>
                    <el-button type="custom_primary" size="medium" @click="searchData">查询</el-button>
                </div>
                <el-button type="custom_primary" size="medium" @click="$router.go(-1)">
                    返回
                </el-button>
            </div>
        </div>
        <ul class="page-tab bold">
            <li class="tab-item" :class="{ current: tabIndex == 10 }" @click="changeTable(10)">新注册</li>
            <li class="tab-item" :class="{ current: tabIndex == 30 }" @click="changeTable(30)">转校</li>
            <li class="tab-item" :class="{ current: tabIndex == 20 }" @click="changeTable(20)">转班</li>
        </ul>
        <div class="table-box">
            <div class="table-inner" ref="tableInner">
                <el-table :data="tableData" height="100%" v-if="tabIndex > 10" @selection-change="tableSelect">
                    <el-table-column align="center" type="selection" width="140" />
                    <el-table-column align="center" label="头像" min-width="14.6%">
                        <template slot-scope="scope">
                            <p class="table-img">
                                <el-avatar :size="56" :src="scope.row.stuser_image">
                                    <img src="@assets/images/empty_avatar.png" />
                                </el-avatar>
                            </p>
                        </template>
                    </el-table-column>
                    <el-table-column align="center" label="姓名" min-width="20%">
                        <template slot-scope="scope">{{ scope.row.stuser_name }}</template>
                    </el-table-column>
                    <el-table-column align="center" label="性别" min-width="9.4%">
                        <template slot-scope="scope">{{ scope.row.stuser_sex == 10 && '男' || '女' }}</template>
                    </el-table-column>
                    <el-table-column align="center" prop="stuser_stuno" label="学号" min-width="15%" />
                    <el-table-column align="center" prop="pauser_names" label="家长" min-width="19%" />
                    <el-table-column align="center" prop="testu_exa_type_text"
                        :label="tabIndex == 30 && '原学校' || tabIndex == 20 && '原班级'" min-width="22%" />
                </el-table>
                <el-table :data="tableData" height="100%" v-if="tabIndex == 10" @selection-change="tableSelect">
                    <el-table-column align="center" type="selection" width="140" />
                    <el-table-column align="center" label="姓名 " min-width="17.27%">
                        <template slot-scope="scope">{{ scope.row.stuser_name }}</template>
                    </el-table-column>
                    <el-table-column align="center" prop="sex" label="性别" min-width="9.39%">
                        <template slot-scope="scope">{{ scope.row.stuser_sex == 10 && '男' || '女' }}</template>
                    </el-table-column>
                    <el-table-column align="center" prop="stuser_account" label="账号" min-width="25.55%" />
                    <el-table-column align="center" prop="stuser_stuno" label="学号" min-width="21.59%" />
                    <el-table-column align="center" prop="testu_exa_type_text" label="来源" min-width="26.2%" />
                </el-table>
            </div>
            <div class="table-foot custom-foot">
                <div class="button-group">
                    <el-button type="custom_success" size="medium" @click="handlingStudents(20)" :disabled="btnload[0]"
                        v-loading="btnload[0]">
                        <span>接收</span>
                    </el-button>
                    <el-button type="custom_danger" size="medium" @click="handlingStudents(30)">
                        <span>退回</span>
                    </el-button>
                </div>
                <customPagination :current='tablePage.pageIndex' :total="tablePage.total" @pageChange="flippingPage" />
            </div>
        </div>
        <el-dialog title="退回理由" width="430px" :visible.sync="backDialog" class="back-dialog" :show-close="false"
            @close="hideDialog">
            <div class="dialog-container">
                <el-form :model="backForm" ref="backForm">
                    <el-form-item prop="testu_exa_cause" :rules="{ required: true, message: '请填写退回理由', trigger: 'blur' }">
                        <el-input v-model.trim="backForm.testu_exa_cause" maxlength="100" placeholder="请填写退回理由" />
                    </el-form-item>
                </el-form>
            </div>
            <div slot="footer">
                <el-button type="custom_info" size="small" @click="hideDialog">
                    取 消
                </el-button>
                <el-button type="custom_primary" size="small" :disabled="btnload[1]" v-loading="btnload[1]"
                    @click="backStudent">
                    确 定
                </el-button>
            </div>
        </el-dialog>
    </section>
</template>

<script>
import { $toDoList, $handlingStudents } from "@api/home"
import customPagination from "@comp/customPagination"
import { mapActions } from "vuex"
export default {
    name: 'home_studentTransfer',
    components: { customPagination },
    data() {
        return {
            searchKey: '',
            classId: null,
            tabIndex: 10,
            tableData: [],
            tablePage: {
                pageIndex: 1,
                total: 0
            },
            selectIds: [],
            btnload: [false, false],
            backDialog: false,
            backForm: {},
        };
    },
    created() {
        if (this.$route.query.id) this.classId = this.$route.query.id
        this.getData();
    },
    methods: {
        ...mapActions('common', ['getClass']),
        /** 获取消息列表 */
        async getData() {
            let params = {
                testu_exa_type: this.tabIndex,
                stuser_name: this.searchKey,
                pageindex: this.tablePage.pageIndex
            }
            if (this.classId) params.sccla_id = this.classId;
            let { data: res } = await $toDoList(params);
            this.tablePage.total = res.allcount;
            this.tableData = res.data;
            this.$forceUpdate();
        },
        /**
         * 操作学生【接收/退回】
         * @param {number} status 20 ==-> 接收  30 ==-> 退回
         */
        async handlingStudents(status) {
            if (this.$isEmpty(this.selectIds)) return this.$message.error('请勾选需要操作的学生!')
            if (status == 30) {
                this.backDialog = true;
                this.btnload[1] = false;
                this.$forceUpdate();
                // this.$prompt('', '退回理由', {
                //     confirmButtonText: '确定',
                //     cancelButtonText: '取消',
                //     inputPlaceholder: '请输入退回理由',
                //     inputValidator: (val) => {
                //         let reg = /^.+$/
                //         if (!reg.test(val)) {

                //         } else if ()
                //     },
                //     inputErrorMessage: '请输入退回理由',
                // }).then(async ({ value }) => {
                //     params.testu_exa_cause = value;
                //     let res = await $handlingStudents(params);
                //     this.btnload[1] = false;
                //     this.$forceUpdate();
                //     if (res) {
                //         this.$message.success('操作成功');
                //         this.getClass();
                //         this.getData();
                //     }
                // }).catch(() => {
                //     this.btnload[1] = false;
                //     this.$forceUpdate();
                //     this.$message({
                //         type: 'info',
                //         message: '操作取消'
                //     });
                // });
            } else {
                this.btnload[0] = true;
                this.$forceUpdate();
                let params = {
                    testu_exa_ids: this.selectIds.join(','),
                    testu_exa_type: this.tabIndex,
                    testu_exa_status: status
                }
                let res = await $handlingStudents(params);
                this.btnload[0] = false;
                this.$forceUpdate();
                if (res) {
                    this.$message.success('操作成功');
                    this.getClass();
                    this.getData();
                }
            }
        },
        /** 退回学生 */
        backStudent() {
            this.$refs.backForm.validate(async (valid) => {
                if (valid) {
                    this.btnload[1] = true;
                    this.$forceUpdate();
                    let params = {
                        ...this.backForm,
                        testu_exa_ids: this.selectIds.join(','),
                        testu_exa_type: this.tabIndex,
                        testu_exa_status: 30,
                    }
                    let res = await $handlingStudents(params);
                    this.btnload[1] = false;
                    this.$forceUpdate();
                    if (res) {
                        this.hideDialog();
                        this.$message.success('操作成功');
                        this.getClass();
                        this.getData();
                    }
                }
            })
        },
        /** 搜索 */
        searchData() {
            this.tableData = [];
            this.tablePage.pageIndex = 1;
            this.getData();
        },
        /** 切换表格数据类型 */
        changeTable(val) {
            this.searchKey = '';
            this.tabIndex = val
            this.searchData();
        },
        /**
         * 表格选项选中值变化
         * @param {array} selection 选中数据
         */
        tableSelect(selection) {
            this.selectIds = selection.map(item => this.tabIndex == 10 && item.stuser_id || item.testu_exa_id);
        },
        /** 翻页 */
        flippingPage(val) {
            this.tablePage.pageIndex = val;
            this.getData();
        },
        /** 关闭弹窗 */
        hideDialog() {
            this.backForm = {};
            this.$refs.backForm.clearValidate();
            this.backDialog = false;
            if(this.btnload[1]) this.btnload[1] = false;
            this.$forceUpdate();
        }
    },
};
</script>